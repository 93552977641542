import request from '@/utils/request'

//获取公告列表
export function getNoticeList(params) {
    return request.get('/api-system/sys-push-log', params)
}

//新增或修改公告
export function noticeAddOrUpdate(params) {
    return request.post('/api-system/sys-push-log/saveOrUpdate', params)
}

//获取公告详情
export function getNotice(id) {
    return request.get('/api-system/sys-push-log/' + id)
}

//删除公告
export function noticeRemove(params) {
    return request.remove('/api-system/sys-push-log/deleteBatch', params)
}

//获取展示公告
export function getNoticeShowList(params) {
    return request.get('/api-system/sys-push-log/show',params)
}